import {
  FacebookRounded,
  Instagram,
  LinkedIn,
  WhatsApp,
  X,
  YouTube,
} from "@mui/icons-material";
import { IconButton, Stack } from "@mui/joy";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from 'react'


const SocialMedia = () => {

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Stack
      direction={{ xs: "row", sm: "row", md: "column" }}
      justifyContent="center"
      alignItems="center"
      spacing={{ xs: 0, sm: 1, md: 4 }}
      sx={
        matches
          ? {
              position: "relative",
              right: 0,
              bottom: "10%",
              top: "10%",
            }
          : { position: "absolute", right: 0, bottom: "10%", top: "10%" }
      }
    >
      <IconButton
        color="#FFF"
        variant="plain"
        sx={{
          "--IconButton-size": "51px",
        }}
      >
        <FacebookRounded sx={{ color: "white" }} />
      </IconButton>
      <IconButton
        color="#FFF"
        variant="plain"
        sx={{
          "--IconButton-size": "51px",
        }}
      >
        <WhatsApp sx={{ color: "white" }} />
      </IconButton>
      <IconButton
        color="#FFF"
        variant="plain"
        sx={{
          "--IconButton-size": "51px",
        }}
      >
        <Instagram sx={{ color: "white" }} />
      </IconButton>
      <IconButton
        color="#FFF"
        variant="plain"
        sx={{
          "--IconButton-size": "51px",
        }}
      >
        <LinkedIn sx={{ color: "white" }} />
      </IconButton>
      <IconButton
        color="#FFF"
        variant="plain"
        sx={{
          "--IconButton-size": "51px",
        }}
      >
        <X sx={{ color: "white" }} />
      </IconButton>
      <IconButton
        color="#FFF"
        variant="plain"
        sx={{
          "--IconButton-size": "51px",
        }}
      >
        <YouTube sx={{ color: "white" }} />
      </IconButton>
    </Stack>
  );
};

export default SocialMedia;
