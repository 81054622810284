import { CloseRounded, KeyboardArrowDown } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  Input,
  Modal,
  ModalClose,
  ModalDialog,
  Sheet,
  Snackbar,
  Typography,
  selectClasses,
} from "@mui/joy";
import { FormControl as FormC, MenuItem, Select } from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import APIContext from "../../context/APIContext";
import AuthContext from "../../context/AuthContext";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";

// eslint-disable-next-line react/prop-types
const NewRecord = ({ addRecord, setAddRecord, fetchRecords }) => {
  const [fileName, setFileName] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const { API_URL } = useContext(APIContext);
  let { authTokens } = useContext(AuthContext);

  let defaultValues = {
    documentNumber: "",
    documentType: "",
    expiry: "",
    issuerPhone: "",
    issuer: "",
    issuerEmail: "",
    ownersMail: "",
    ownersName: "",
    ownersPhone: "",
    country: "",
    institution: "",
    advocateName: "",
    advocateRegNo: "",
    advocateLicenseNo: "",
    advocatePhoneNo: "",
    advocateEmail: "",
    issuingDate: "",
  };
  const [values, setValues] = useState(defaultValues);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    console.log(values);
  };

  const [exceeded, setExceeded] = useState(false);

  const checkFile = () => {
    if (fileName.size > 26214400) {
      setExceeded(true);
    } else {
      setExceeded(false);
    }
  };

  useEffect(() => {
    checkFile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileName]);

  useEffect(() => {
    setValues(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const saveFile = () => {
    let formData = new FormData();
    let data = { file: fileName, ...values };
    console.log(data);
    formData.append("data", data);

    let axiosConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + String(authTokens.access),
      },
    };

    axios
      .post(`${API_URL}api/upload/`, data, axiosConfig)
      .then((response) => {
        setAddRecord(false);
        setOpenSnackbar(true);
        fetchRecords();
      })
      .catch((error) => {
        setAddRecord(false);
      });
  };

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Sheet
      sx={{
        p: matches ? 2 : 4,
        opacity: 0.9,
        maxWidth: 600,
        background: "white",
        mt: 12,
        textAlign: "justify",
        mb: matches ? 1 : 10,
        ml: matches ? 1 : 0,
        mr: matches ? 1 : 0,
      }}
    >
      <Box>
        <>
          <Sheet
            sx={{
              minWidth: 300,
              borderRadius: "md",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography component="h2" level="h4" fontWeight="lg">
              Upload a new file record
            </Typography>
            <IconButton onClick={() => setAddRecord(false)}>
              <CloseRounded />
            </IconButton>
          </Sheet>
          <br />
          <form
            onSubmit={(e) => {
              e.preventDefault();
              saveFile();
            }}
          >
            <Grid container spacing={3} sx={{ flexGrow: 1 }}>
              <Grid xs={12} md={6}>
                <FormC fullWidth sx={{ mt: 0.5, minWidth: 120 }} size="small">
                  <FormLabel>Document Type:</FormLabel>
                  <Select
                    fullWidth
                    required
                    onChange={handleChange("documentType")}
                    placeholder="Intstitution Type"
                    indicator={<KeyboardArrowDown />}
                    value={values.documentType}
                    sx={{
                      [`& .${selectClasses.indicator}`]: {
                        transition: "0.2s",
                        [`&.${selectClasses.expanded}`]: {
                          transform: "rotate(-180deg)",
                        },
                      },
                    }}
                  >
                  
                    <MenuItem value="Primary School Certificate">
                      Primary School Certificate
                    </MenuItem>
                    <MenuItem value="High School Certificate">
                      High School Certificate
                    </MenuItem>
                    <MenuItem value="College Certificate">
                      College Certificate
                    </MenuItem>
                    <MenuItem value="College Diploma">College Diploma</MenuItem>
                    <MenuItem value="University Degree">
                      University Degree
                    </MenuItem>
                    <MenuItem value="Medical Certificate Report">
                      Medical Certificate Report
                    </MenuItem>
                    <MenuItem value="Professional Membership Certificate">
                      Professional Membership Certificate
                    </MenuItem>
                    <MenuItem value="Company/Business Registration Certificate">
                      Company/Business Registration Certificate
                    </MenuItem>
                    <MenuItem value="Corporate Membership Certificate">
                      Corporate Membership Certificate
                    </MenuItem>
                    <MenuItem value="Bank/Insurance Tender Security">
                      Bank/Insurance Tender Security
                    </MenuItem>
                    <MenuItem value="Project Bank Performance Guarantee">
                      Project Bank Performance Guarantee
                    </MenuItem>
                    <MenuItem value="Contract Award Letter">
                      Contract Award Letter
                    </MenuItem>
                    <MenuItem value="Contract Completion Certificate">
                      Contract Completion Certificate
                    </MenuItem>
                  </Select>
                </FormC>
              </Grid>
              <Grid xs={12} md={6}>
                <FormControl required>
                  <FormLabel>Document Number:</FormLabel>
                  <Input onChange={handleChange("documentNumber")} />
                </FormControl>
              </Grid>
              <Grid xs={12} md={6}>
                <FormControl required>
                  <FormLabel>Owner’s Name:</FormLabel>
                  <Input onChange={handleChange("ownersName")} />
                </FormControl>
              </Grid>
              <Grid xs={12} md={6}>
                <FormControl required>
                  <FormLabel>Date of Issue:</FormLabel>
                  <Input type="date" onChange={handleChange("issuingDate")} />
                </FormControl>
              </Grid>
              <Grid xs={12} md={6}>
                <FormControl required>
                  <FormLabel>Owners Phone Number:</FormLabel>
                  <Input onChange={handleChange("ownersPhone")} />
                </FormControl>
              </Grid>
              <Grid xs={12} md={6}>
                <FormControl required>
                  <FormLabel>Owners Email Address:</FormLabel>
                  <Input type="email" onChange={handleChange("ownersMail")} />
                </FormControl>
              </Grid>
              <Grid xs={12} md={6}>
                <FormControl required>
                  <FormLabel>Issuing Body/Institution:</FormLabel>
                  <Input onChange={handleChange("issuer")} />
                </FormControl>
              </Grid>
              <Grid xs={12} md={6}>
                <FormControl required>
                  <FormLabel>Email of the Issuing Body:</FormLabel>
                  <Input onChange={handleChange("issuerEmail")} />
                </FormControl>
              </Grid>
              <Grid xs={12} md={6}>
                <FormControl required>
                  <FormLabel>Phone No of the Issuing Body:</FormLabel>
                  <Input onChange={handleChange("issuerPhone")} />
                </FormControl>
              </Grid>
              <Grid xs={12} md={6}>
                <FormControl>
                  <FormLabel>Expires on:</FormLabel>
                  <Input type="date" onChange={handleChange("expiry")} />
                </FormControl>
              </Grid>

              <Grid xs={12} md={6}>
                <FormC fullWidth sx={{ mt: 0.5, minWidth: 120 }} size="small">
                  <FormLabel>Country:</FormLabel>
                  <Select
                    fullWidth
                    onChange={handleChange("country")}
                    placeholder="Select a country"
                    indicator={<KeyboardArrowDown />}
                    value={values.country}
                    required
                    sx={{
                      [`& .${selectClasses.indicator}`]: {
                        transition: "0.2s",
                        [`&.${selectClasses.expanded}`]: {
                          transform: "rotate(-180deg)",
                        },
                      },
                    }}
                  >
                    <MenuItem value="Angola">Angola</MenuItem>
                    <MenuItem value="Botswana">Botswana</MenuItem>
                    <MenuItem value="Cameroon">Cameroon</MenuItem>
                    <MenuItem value="Egypt">Egypt</MenuItem>
                    <MenuItem value="Eritrea">Eritrea</MenuItem>
                    <MenuItem value="Eswatini (the Kingdom of)">
                      Eswatini (the Kingdom of)
                    </MenuItem>
                    <MenuItem value="Ethiopia">Ethiopia</MenuItem>
                    <MenuItem value="The Gambia">The Gambia</MenuItem>
                    <MenuItem value="Ghana">Ghana</MenuItem>
                    <MenuItem value="Ivory Coast">Ivory Coast</MenuItem>
                    <MenuItem value="Kenya">Kenya</MenuItem>
                    <MenuItem value="Lesotho">Lesotho</MenuItem>
                    <MenuItem value="Liberia">Liberia</MenuItem>
                    <MenuItem value="Libya">Libya</MenuItem>
                    <MenuItem value="Malawi">Malawi</MenuItem>
                    <MenuItem value="Mauritius">Mauritius</MenuItem>
                    <MenuItem value="Mozambique">Mozambique</MenuItem>
                    <MenuItem value="Namibia">Namibia</MenuItem>
                    <MenuItem value="Nigeria">Nigeria</MenuItem>
                    <MenuItem value="Republic of South Sudan">
                      Republic of South Sudan
                    </MenuItem>
                    <MenuItem value="Rwanda">Rwanda</MenuItem>
                    <MenuItem value="Senegal">Senegal</MenuItem>
                    <MenuItem value="Seychelles">Seychelles</MenuItem>
                    <MenuItem value="Sierra Leone">Sierra Leone</MenuItem>
                    <MenuItem value="Somalia">Somalia</MenuItem>
                    <MenuItem value="South Africa">South Africa</MenuItem>
                    <MenuItem value="Sudan">Sudan</MenuItem>
                    <MenuItem value="Uganda">Uganda</MenuItem>
                    <MenuItem value="United Republic of Tanzania">
                      United Republic of Tanzania
                    </MenuItem>
                    <MenuItem value="Zambia">Zambia</MenuItem>
                    <MenuItem value="Zimbabwe">Zimbabwe</MenuItem>
                  </Select>
                </FormC>
              </Grid>
              <Grid xs={12} md={6}>
                <FormC fullWidth sx={{ mt: 0.5, minWidth: 120 }} size="small">
                  <FormLabel>Institution Type:</FormLabel>
                  <Select
                    fullWidth
                    required
                    onChange={handleChange("institution")}
                    placeholder="Intstitution Type"
                    indicator={<KeyboardArrowDown />}
                    value={values.institution}
                    sx={{
                      [`& .${selectClasses.indicator}`]: {
                        transition: "0.2s",
                        [`&.${selectClasses.expanded}`]: {
                          transform: "rotate(-180deg)",
                        },
                      },
                    }}
                  >
                    <MenuItem value="Government Ministry">
                      Government Ministry
                    </MenuItem>
                    <MenuItem value="Bank">Bank</MenuItem>
                    <MenuItem value="School">School</MenuItem>
                    <MenuItem value="College">College</MenuItem>
                    <MenuItem value="University">University</MenuItem>
                    <MenuItem value="Parastal">Parastal</MenuItem>
                    <MenuItem value="NGO">NGO</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                </FormC>
              </Grid>

              <Grid xs={12} md={6}>
                <FormControl required>
                  <FormLabel>Advocate’s Name:</FormLabel>
                  <Input onChange={handleChange("advocateName")} />
                </FormControl>
              </Grid>
              <Grid xs={12} md={6}>
                <FormControl required>
                  <FormLabel>Advocate’s Registration Number:</FormLabel>
                  <Input onChange={handleChange("advocateRegNo")} />
                </FormControl>
              </Grid>

              <Grid xs={12} md={6}>
                <FormControl required>
                  <FormLabel>Advocate’s Practicing License Number:</FormLabel>
                  <Input onChange={handleChange("advocateLicenseNo")} />
                </FormControl>
              </Grid>

              <Grid xs={12} md={6}>
                <FormControl required>
                  <FormLabel>Advocate’s Phone Number:</FormLabel>
                  <Input onChange={handleChange("advocatePhoneNo")} />
                </FormControl>
              </Grid>

              <Grid xs={12} md={6}>
                <FormControl required>
                  <FormLabel>Advocate’s Email Address:</FormLabel>
                  <Input
                    type="email"
                    onChange={handleChange("advocateEmail")}
                  />
                </FormControl>
              </Grid>

              <Grid xs={12} md={6}>
                <FormControl required>
                  <FormLabel>Document:</FormLabel>
                  <input
                    required
                    max={1000}
                    type="file"
                    accept=".pdf, .xls"
                    onChange={(e) => {
                      setFileName(e.target.files[0]);
                    }}
                    style={{ display: "none" }}
                    id="multiple-file-input"
                  />
                  <label htmlFor="multiple-file-input">
                    <Button variant="outlined" component="span">
                      Select File
                    </Button>
                  </label>
                  <FormHelperText>
                    {exceeded ? (
                      <Typography color="danger">
                        {fileName?.name} exceeds a limit of 25mbs
                      </Typography>
                    ) : (
                      <Typography mt={2}>{fileName?.name}</Typography>
                    )}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid xs={12}>
                <Button
                  variant="solid"
                  color="primary"
                  type="submit"
                  mt={2}
                  disabled={exceeded || fileName === ""}
                >
                  Upload Details
                </Button>
              </Grid>
            </Grid>
          </form>
        </>
      </Box>
      <Snackbar
        variant="solid"
        color="success"
        open={openSnackbar}
        onClose={() => {
          setOpenSnackbar(false);
        }}
      >
        Record was submitted successfully
      </Snackbar>
    </Sheet>
  );
};

export default NewRecord;
