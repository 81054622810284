import { CloseRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  DialogContent,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Modal,
  ModalClose,
  ModalDialog,
  Sheet,
  Table,
  Tooltip,
  Typography,
} from "@mui/joy";
import axios from "axios";
import React, { useContext, useEffect } from "react";
import { Transition } from "react-transition-group";
import APIContext from "../context/APIContext";
//import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
import Footer from "../components/Footer";
import SocialMedia from "../components/SocialMedia";
import "../hero.css";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const HomePage = () => {
  const [open, setOpen] = React.useState(false);
  const [searched, setSearched] = React.useState(false);

  let { API_URL } = useContext(APIContext);
  let [value, setValue] = React.useState("");
  let [searchResult, setSearchResult] = React.useState(null);
  let [error, setError] = React.useState(false);

  const handleSearch = async () => {
    try {
      let response = await axios.post(`${API_URL}api/search/`, {
        value,
      });
      let data = response.data[0];
      setSearchResult(data);
    } catch (err) {
      setError(true);
    }
  };

  // update the searchResult and only show the search results after the update
  useEffect(() => {
    if (searchResult !== null) {
      setSearched(true);
      console.log(searchResult);
    }
  }, [searchResult]);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "3rem",
          background: "transparent",

          zIndex: "1000",
          maxWidth: "100vw",
          minHeight: "fit-content",
        }}
      >
        {/*Search bar: And allow search after pressing the enter key*/}
        {!searched && (
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
              justifyContent: "center",
              gap: "1rem",
            }}
          >
            <Input
              placeholder="Document ID OR Serial Number "
              onChange={(e) => {
                setValue(e.target.value), setError(false);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSearch();
                }
              }}
              size="lg"
              sx={{
                maxWidth: matches ? "60vw" : "45vw",
              }}
            />
            <Button type="submit" size="lg" onClick={handleSearch}>
              Verify
            </Button>
          </Box>
        )}

        {/* The table will only appear after the search button has been clicked and fetch request is done */}
        {searched && (
          <Sheet
            sx={{
              height: { xs: "100%", sm: "100%", md: "75vh" },
              overflow: "auto",
              opacity: 1,
              mb: { xs: "2rem", sm: "2rem" },
              mt: { xs: "6rem", sm: "6rem", md: "0rem" },
              ml: { xs: ".5rem", sm: ".5rem" },
              mr: { xs: ".5rem", sm: ".5rem" },
            }}
          >
            <Table
              color="neutral"
              variant="soft"
              stripe="odd"
              size="sm"
              overflow="scroll"
              stickyFooter
              stickyHeader
              hoverRow
              sx={{
                border: "none",
                borderRadius: "10px",
                maxWidth: 600,
                margin: 0,
                opacity: 0.8,
              }}
              align="left"
            >
              <thead>
                <tr>
                  <th></th>

                  <th>
                    <Tooltip
                      title="Close"
                      sx={{ right: 0, position: "absolute", top: 3 }}
                    >
                      <IconButton
                        size="sm"
                        color="danger"
                        variant="solid"
                        onClick={() => setSearched(false)}
                      >
                        <CloseRounded />
                      </IconButton>
                    </Tooltip>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Typography
                      level="body-md"
                      color={
                        searchResult.verified === "Verified"
                          ? "success"
                          : searchResult.verified === "Pending"
                          ? "primary"
                          : "danger"
                      }
                    >
                      {searchResult.verified}
                    </Typography>
                  </td>
                  <td>
                    <Typography
                      level="body-md"
                      color={
                        searchResult.verified === "Verified"
                          ? "success"
                          : searchResult.verified === "Pending"
                          ? "primary"
                          : "danger"
                      }
                    >
                      {searchResult.serial_no}
                    </Typography>
                  </td>
                </tr>
                <tr>
                  <td>Institution’s Country</td>
                  <td>{searchResult.country}</td>
                </tr>
                <tr>
                  <td>Document Type:</td>
                  <td>{searchResult.document_type}</td>
                </tr>
                <tr>
                  <td>Document Identification Number:</td>
                  <td>{searchResult.document_number}</td>
                </tr>
                <tr>
                  <td>Date of Issue:</td>
                  <td>{searchResult.date_of_issue}</td>
                </tr>
                {searchResult.advocate !== null && (
                  <>
                    <tr>
                      <td colSpan={2}>
                        <Typography color="primary" level="body-md">
                          TRUE COPY OF THE ORIGINAL ATTESTED BY A COMMISSIONER
                          OF OATHS//NOTARY PUBLIC
                        </Typography>
                      </td>
                    </tr>

                    <tr>
                      <td>Advocate’s Name:</td>
                      <td>{searchResult.advocate_name}</td>
                    </tr>
                    <tr>
                      <td>Advocate’s Registration Number:</td>
                      <td>{searchResult.advocate_reg_no}</td>
                    </tr>
                    <tr>
                      <td>Advocate’s Practicing License Number:</td>
                      <td>{searchResult.advocate_license_no}</td>
                    </tr>
                    <tr>
                      <td>Advocate’s Phone Number:</td>
                      <td>{searchResult.advocate_phone_no}</td>
                    </tr>
                    <tr>
                      <td>Advocate’s Email Address:</td>
                      <td>{searchResult.advocate_email}</td>
                    </tr>
                  </>
                )}
                <tr>
                  <td colSpan={2}>
                    <Typography color="primary" level="body-md">
                      DOCUMENT OWNER’S DETAILS
                    </Typography>
                  </td>
                </tr>
                <tr>
                  <td>Owner’s Full Name</td>
                  <td>{searchResult.owners_name}</td>
                </tr>
                <tr>
                  <td>Owner’s Phone Number:</td>
                  <td>{searchResult.owners_phone_number}</td>
                </tr>
                <tr>
                  <td>Owners Email Address:</td>
                  <td>{searchResult.owners_mail_address}</td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <Typography color="primary" level="body-md">
                      ISSUING BODY/INSTITUTION’S DETAILS
                    </Typography>
                  </td>
                </tr>
                <tr>
                  <td>Type:</td>
                  <td>{searchResult.institution}</td>
                </tr>
                <tr>
                  <td>Name:</td>
                  <td>{searchResult.issuing_body}</td>
                </tr>
                <tr>
                  <td>Email Address:</td>
                  <td>{searchResult.email_of_issuing_body}</td>
                </tr>
                <tr>
                  <td>Phone Number:</td>
                  <td>{searchResult.phone_of_issuing_body}</td>
                </tr>
                <tr>
                  <td>Institution’s Country</td>
                  <td>{searchResult.country}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={2} style={{ textAlign: "center" }}>
                    <Button onClick={() => setOpen(true)}>Download File</Button>
                  </td>
                </tr>
              </tfoot>
            </Table>
          </Sheet>
        )}

        {/* Error message if there is an error in the request */}
        {error && (
          <Card color="danger" invertedColors variant="soft">
            <Typography sx={{ flex: "" }} level="title-md">
              {value} does not exist in our records.
            </Typography>
          </Card>
        )}
      </Box>
      {/*Pop up modal to display the search results*/}
      <Transition in={open} timeout={400}>
        {(state) => (
          <Modal
            keepMounted
            open={!["exited", "exiting"].includes(state)}
            slotProps={{
              backdrop: {
                sx: {
                  opacity: 0,
                  backdropFilter: "none",
                  transition: `opacity 400ms, backdrop-filter 400ms`,
                  ...{
                    entering: { opacity: 1, backdropFilter: "blur(.5px)" },
                    entered: { opacity: 1, backdropFilter: "blur(.5px)" },
                  }[state],
                },
              },
            }}
            sx={{
              visibility: state === "exited" ? "hidden" : "visible",
            }}
          >
            <ModalDialog
              sx={{
                opacity: 0,
                transition: `opacity 300ms`,
                ...{
                  entering: { opacity: 1 },
                  entered: { opacity: 1 },
                }[state],
              }}
            >
              <ModalClose onClick={() => setOpen(false)} />
              <Sheet
                sx={{
                  minWidth: 300,
                  borderRadius: "md",
                  padding: "2rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <Typography
                  component="h2"
                  id="close-modal-title"
                  level="h4"
                  fontWeight="lg"
                >
                  Pay Kenya Shilling 20/- to download the file
                </Typography>
                <Divider />
                <FormControl>
                  <FormLabel>Phone Number:</FormLabel>
                  <Input
                    fullWidth
                    sx={{
                      "--Input-radius": "10px",
                      "--Input-paddingInline": "20px",
                      "--Input-decoratorChildHeight": "40px",
                    }}
                  />
                </FormControl>
                <Typography
                  component="h2"
                  level="h4"
                  sx={{ textAlign: "center" }}
                >
                  or
                </Typography>
                <FormControl>
                  <FormLabel>Reference Code:</FormLabel>
                  <Input
                    sx={{
                      "--Input-radius": "10px",
                      "--Input-paddingInline": "20px",
                      "--Input-decoratorChildHeight": "40px",
                    }}
                  />
                </FormControl>
                <Button>Pay</Button>
                <Divider />
                <Typography
                  component="h2"
                  level="body-md"
                  sx={{ textAlign: "center" }}
                >
                  Paybill Details
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Typography level="body-md">
                    Paybill Number: 12345
                    <br />
                    Account Number: abcd12
                  </Typography>
                  <Typography level="body-md">
                    Amount: 20/-
                    <br />
                    PIN: Enter your PIN
                  </Typography>
                </Box>
              </Sheet>
              <DialogContent></DialogContent>
            </ModalDialog>
          </Modal>
        )}
      </Transition>
      {!matches && <SocialMedia />}

      <Footer />
    </>
  );
};

export default HomePage;
