import { Box, Divider, Grid, Link, Stack, Typography } from "@mui/joy";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import SocialMedia from "./SocialMedia";
import React from 'react'

const Footer = () => {
  let navigate = useNavigate();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <Grid
        container
        sx={{
          position: "absolute",
          bottom: "0%",
          left: "10%",
          marginBottom: "2rem",
          display: "flex",
          justifyContent: "space-between",
          width: "80vw",
          color: "#FFF",
        }}
      >
        <Grid xs={7} sm={7} md={7}>
          <Stack
            direction={{ xs: "column", sm: "column", md: "row" }}
            divider={<Divider orientation="vertical" />}
            spacing={{ xs: 0, sm: 1, md: 2 }}
            justifyContent="center"
          >
            <Link color="inherit" onClick={() => navigate("/about-us")}>
              About Us
            </Link>
            <Link color="inherit" onClick={() => navigate("/why-us")}>
              Why Copy Verification
            </Link>
            {location.pathname !== "/" && (
              <Link color="inherit" onClick={() => navigate("/")}>
                Home
              </Link>
            )}

            <Link color="inherit">Countries</Link>
          </Stack>
        </Grid>

        <Grid sm={5} xs={5} md={5}>
          <Box>
            <Typography variant="body2" color="text.secondary" align="right">
              Copy Validator®
            </Typography>
            <Typography
              sx={{ textAlign: "right" }}
              variant="body2"
              color="text.secondary"
              align="center"
            >
              {"©"}

              {new Date().getFullYear()}
              {" All rights reserved."}
            </Typography>
          </Box>
        </Grid>

        {matches && (
          <Grid xs={12}>
            <SocialMedia />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default Footer;
