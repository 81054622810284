import {
  MailRounded,
  PasswordRounded,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Link,
  Typography,
} from "@mui/joy";
import { useContext, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import React from 'react'


const LoginPage = () => {
  const [values, setValues] = useState({
    email: "",
    password: "",
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  let { loginUser, loginError } = useContext(AuthContext);

  return (
    <Card
      variant="soft"
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "2.5rem",
          minWidth: "30vw",
          textAlign: "center",
        }}
      >
        <Typography level="h2">Sign in</Typography>
        <Typography level="body-lg">
          New to the company?{" "}
          <Link component={RouterLink} to="/create-account" underline="hover">
            Sign Up
          </Link>
        </Typography>
        {loginError && (
          <Typography
            level="body-lg"
            color="danger"
            sx={{ fontWeight: "bold" }}
          >
            Incorrect email or pasword.
          </Typography>
        )}
        <FormControl>
          <FormLabel>Email:</FormLabel>
          <Input
            type="email"
            onChange={handleChange("email")}
            startDecorator={<MailRounded />}
            sx={{
              "--Input-radius": "10px",
              "--Input-paddingInline": "20px",
              "--Input-decoratorChildHeight": "45px",
            }}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Password:</FormLabel>
          <Input
            type={values.showPassword ? "text" : "password"}
            value={values.password}
            onChange={handleChange("password")}
            startDecorator={<PasswordRounded />}
            sx={{
              "--Input-radius": "10px",
              "--Input-paddingInline": "20px",
              "--Input-decoratorChildHeight": "40px",
            }}
            endDecorator={
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {values.showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            }
          />
        </FormControl>
        <Typography
          level="body-md"
          component={RouterLink}
          to="/request-reset-url"
        >
          Forgot your password?
        </Typography>
        <Button onClick={() => loginUser(values)}>Sign in</Button>
      </CardContent>
    </Card>
  );
};

export default LoginPage;
