/* eslint-disable no-unused-vars */
import {
  MailRounded,
  PasswordRounded,
  Person2Rounded,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  Alert,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  Link,
  Typography,
} from "@mui/joy";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import APIContext from "../context/APIContext";
import PrivacyPolicy from "../components/PrivacyPolicy";

const SignupPage = () => {
  let { API_URL } = useContext(APIContext);
  let navigate = useNavigate();
  let defaultData = { name: "", email: "", password: "", repeatPassword: "" };
  const [values, setValues] = useState(defaultData);

  // custom rule will have name 'isPasswordMatch'
  let [isInvalid, setIsInvalid] = useState(false);
  const [submitErrors, setSubmitErrors] = useState(true);
  const [disabled, setDisabled] = useState(false);
  let [passwordMismatch, setPasswordMismatch] = useState(false);
  let [passwordError, setPasswordError] = useState(false);
  let [emailError, setEmailError] = useState(false);
  let [nameError, setNameError] = useState(false);
  let [fieldValidated, setFieldValidated] = useState({
    name: false,
    email: false,
    password: false,
    repeatPassword: false,
  });

  let [emailHelperText, setEmailHelperText] = useState("Enter a Valid Email");
  let [nameHelperText, setNameHelperText] = useState(
    "Enter a unique name(5 characters minimum, 	No special characters)"
  );
  const letterNumber = /^[0-9a-zA-Z]+$/;

  const handleChange = (prop) => (event) => {
    setSubmitErrors(false);
    setIsInvalid(false);
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  //Verify detail entered in real time
  let handleVerification = () => {
    const regex =
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (values.email.length > 0 && regex.test(values.email) === false) {
      setEmailError(true);
    } else {
      setEmailError(false);
      setFieldValidated({ fieldValidated, email: true });
    }
    if (values.password.length > 0 && values.password.length < 6) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
      setFieldValidated({ ...fieldValidated, password: true });
    }
    if (values.password !== values.repeatPassword) {
      setPasswordMismatch(true);
    } else {
      setPasswordMismatch(false);
      setFieldValidated({ ...fieldValidated, repeatPassword: true });
    }
  };

  //Ceck for null constraints submit form only if its not empty and has no errors
  let handleSubmitData = () => {
    if (
      values.name.length > 0 &&
      values.email.length > 0 &&
      values.password.length > 0 &&
      values.repeatPassword.length > 0 &&
      emailError === false &&
      nameError === false &&
      passwordError === false &&
      passwordMismatch === false
    ) {
      sendData();
    } else {
      setIsInvalid(true);
    }
  };

  useEffect(() => {
    if (
      (values.name.length > 0 && letterNumber.test(values.name) === false) ||
      (values.name.length > 0 && values.name.length < 5)
    ) {
      setNameError(true);
    } else {
      setFieldValidated({ fieldValidated, name: true });
      setNameError(false);
    }
    handleVerification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  let sendData = async () => {
    setDisabled(true);
    try {
      let res = await axios.post(`${API_URL}api/register/`, {
        username: values.name,
        email: values.email,
        password: values.password,
      });
      if (res.status === 201) {
        navigate("/login");
      }
    } catch (error) {
      if (error.response) {
        setIsInvalid(true);
        const errorData = error.response.data;
        let { email, username } = errorData;
        if (email !== undefined) {
          setEmailError(true);
          setEmailHelperText("User with this email already exists");
        }
        if (username !== undefined) {
          setNameError(true);
          setNameHelperText("User with this name already exists");
        }
      }
    }
    setDisabled(false);
  };
  let handleSubmit = (e) => {
    e.preventDefault();
    handleSubmitData();
  };

  let [openPolicy, setOpenPolicy] = useState(false);
  const [acceptPolicy, setAcceptPolicy] = useState(true);
  const [acceptPolicy1, setAcceptPolicy1] = useState(true);


  return (
    <>
      <Card
        variant="soft"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: ".5rem",
            minWidth: "30vw",
            textAlign: "center",
            maxWidth: "90vw",
          }}
          component="form"
          onSubmit={handleSubmit}
        >
          <Typography level="h2">Create Account</Typography>

          {isInvalid === true && (
            <div style={{ marginBottom: "2em" }}>
              <Alert severity="error">
                You have some errors in your form — check them out!
              </Alert>
            </div>
          )}
          <FormControl>
            <FormLabel>Username:</FormLabel>
            <Input
              error={nameError}
              onChange={handleChange("name")}
              value={values.name}
              required
              startDecorator={<Person2Rounded />}
              sx={{
                "--Input-radius": "10px",
                "--Input-paddingInline": "20px",
                "--Input-decoratorChildHeight": "40px",
              }}
            />
            {nameError && <FormHelperText>{nameHelperText}</FormHelperText>}
          </FormControl>
          <FormControl>
            <FormLabel>Email:</FormLabel>
            <Input
              error={emailError}
              onChange={handleChange("email")}
              value={values.email}
              required
              startDecorator={<MailRounded />}
              sx={{
                "--Input-radius": "10px",
                "--Input-paddingInline": "20px",
                "--Input-decoratorChildHeight": "40px",
              }}
            />
            {emailError && <FormHelperText>{emailHelperText}</FormHelperText>}
          </FormControl>
          <FormControl>
            <FormLabel>Password:</FormLabel>
            <Input
              error={passwordError}
              onChange={handleChange("password")}
              value={values.password}
              type={values.showPassword ? "text" : "password"}
              required
              startDecorator={<PasswordRounded />}
              endDecorator={
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              }
              sx={{
                "--Input-radius": "10px",
                "--Input-paddingInline": "20px",
                "--Input-decoratorChildHeight": "40px",
              }}
            />
            {passwordError && (
              <FormHelperText>6 Characters Minimum</FormHelperText>
            )}
          </FormControl>
          <FormControl>
            <FormLabel>Confirm:</FormLabel>
            <Input
              error={passwordMismatch}
              onChange={handleChange("repeatPassword")}
              value={values.repeatPassword}
              type={values.showPassword ? "text" : "password"}
              required
              startDecorator={<PasswordRounded />}
              endDecorator={
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              }
              sx={{
                "--Input-radius": "10px",
                "--Input-paddingInline": "20px",
                "--Input-decoratorChildHeight": "40px",
              }}
            />
            {passwordMismatch && (
              <FormHelperText>Password Mismatch</FormHelperText>
            )}
          </FormControl>
          <FormControl
            size="sm"
            sx={{ width: { xs: 380, sm: 380, md: 500 }, textAlign: "left" }}
          >
            <Checkbox
              defaultChecked
              onChange={() => 
                setAcceptPolicy(!acceptPolicy)
              }
              label={
                <React.Fragment>
                  I have read and agree to the{" "}
                  <Typography fontWeight="md">terms and conditions</Typography>.
                </React.Fragment>
              }
            />
            <FormHelperText>
              <Typography level="body-sm">
                Read our{" "}
                <Link onClick={() => setOpenPolicy(true)}>
                  terms and conditions
                </Link>
                .
              </Typography>
            </FormHelperText>
          </FormControl>
          <FormControl
            size="sm"
            sx={{ width: { xs: 350, sm: 350, md: 500 }, textAlign: "left" }}
          >
            <Checkbox
              defaultChecked
              onChange={() => setAcceptPolicy1(!acceptPolicy1)}
              label="I declare my uploads as non-confidential and hereby give consent for the copy validator to sustain itself through public authentication of my true copies in the form of a storage fee per file only if they choose to download."
            />
          </FormControl>
          <Button type="submit" disabled={!acceptPolicy || !acceptPolicy1}>
            Sign Up
          </Button>
          <Typography level="body-lg">
            Already have an account?{" "}
            <Link component={RouterLink} to="/login" underline="hover">
              Sign In
            </Link>
          </Typography>
        </CardContent>
      </Card>
      <PrivacyPolicy openPolicy={openPolicy} setOpenPolicy={setOpenPolicy} />
    </>
  );
};

export default SignupPage;
