import { Alert, Box, Button, Card, Typography } from "@mui/joy";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { useContext, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Link as RouterLink } from "react-router-dom";
import APIContext from "../context/APIContext";
import React from 'react'


let useStyles = makeStyles({
  container: {
    textAlign: "center",
    alignItems: "center",
    resize: "horizontal",
    "--icon-size": "100px",
    minWidth: "40vw",
    padding: "5rem",
    display: "flex",
    flexDirection: "column",
    maxWidth: "fit-content",
    gap: "2rem",
  },
  alert: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
});

// eslint-disable-next-line react/prop-types
const RequestResetEmail = () => {
  let { API_URL } = useContext(APIContext);
  let classes = useStyles();

  const [email, setEmail] = useState("");
  const [linkRequested, setLinkRequested] = useState(false);

  let resetEmailRequest = async () => {
    try {
      await axios.post(`${API_URL}api/request-reset-email/`, {
        email: email,
        redirect_url: `http://localhost:5173/reset-password/`,
      });
      setLinkRequested(true);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box
      sx={{
        position: "relatve",
        minHeight: "80vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        m: { xs: "1rem", sm: "1rem",md:"0rem" },
      }}
    >
      {linkRequested === true ? (
        <Alert variant="outlined" severity="success" className={classes.alert}>
          A new reset password link has been sent. Please check your mails and
          use the link to reset your password.
        </Alert>
      ) : (
        <Card variant="plain" className={classes.container}>
          <Typography level="h2">Reset Your Password</Typography>
          <Typography level="body-md">
            You can reset your Password by providing your email address.
          </Typography>
          <ValidatorForm
            style={{ margin: 0, padding: 0 }}
            align="center"
            noValidate
            autoComplete="off"
            onSubmit={() => resetEmailRequest()}
          >
            <TextValidator
              placeholder="Enter your email address"
              sx={{
                "--Input-radius": "10px",
                "--Input-paddingInline": "20px",
                "--Input-decoratorChildHeight": "40px",
              }}
              onChange={(event) => setEmail(event.target.value)}
              name="email"
              value={email}
              validators={["required", "isEmail"]}
              errorMessages={["This field is required", "Email is not valid"]}
              fullWidth
            />
            <br />
            <br />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "5rem",
              }}
            >
              <Button
                variant="plain"
                color="neutral"
                component={RouterLink}
                to="/login"
              >
                Return to Login
              </Button>
              <Button variant="solid" type="submit" color="primary">
                Submit
              </Button>
            </Box>
          </ValidatorForm>
        </Card>
      )}
    </Box>
  );
};

export default RequestResetEmail;
