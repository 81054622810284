import { Button, Typography } from "@mui/joy";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import React,{ useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import logo from "../assets/logo.png";
import { Fab } from "@mui/material";
import { LockOpenRounded, PostAddRounded } from "@mui/icons-material";
import DropdownMenu from "./DropdownMenu";

const logoStyle = {
  width: "140px",
  height: "auto",
  cursor: "pointer",
};

const NavigationBar = () => {
  // const [open, setOpen] = React.useState(false);

  const navigate = useNavigate();
  let { user, logoutUser } = useContext(AuthContext);


  return (
    <div>
      <AppBar
        position="relative"
        sx={{
          boxShadow: 0,
          bgcolor: "transparent",
          backgroundImage: "none",
          mt: 2,
          zIndex:100000
        }}
      >
        <Container maxWidth="xl">
          <Toolbar
            variant="regular"
            sx={(theme) => ({
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexShrink: 0,
              borderRadius: "999px",
              bgcolor:
                theme.palette.mode === "light"
                  ? "rgba(255, 255, 255, 0)"
                  : "rgba(0, 0, 0, 0)",
              //backdropFilter: "blur(24px)",
              maxHeight: 40,
              border: "none",
              borderColor: "divider",
            })}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
                ml: "-18px",
                px: 0,
              }}
            >
              <img
                src={logo}
                style={logoStyle}
                alt="logo of Copy Validator"
                onClick={() => navigate("/")}
              />
            </Box>
            {location.pathname == "/login" ||
            location.pathname == "/create-account" ||
            location.pathname == "/request-reset-url" ||
            location.pathname == "/reset-password/:id/:id" ? null : (
              <>
                <Box
                  sx={{
                    display: { xs: "none", md: "flex" },

                    gap: 0.5,
                    alignItems: "center",
                  }}
                >
                  {user.username === "guest" ? (
                    <>
                      <Fab
                        variant="extended"
                        color="success"
                        onClick={() => navigate("/login")}
                      >
                        <LockOpenRounded sx={{ mr: 1 }} />
                        Login
                      </Fab>

                      <Fab
                        variant="extended"
                        color="primary"
                        elevation={5}
                        onClick={() => navigate("/create-account")}
                      >
                        <PostAddRounded sx={{ mr: 1 }} />
                        Sign up
                      </Fab>
                    </>
                  ) : (
                    <>
                      <Typography
                        level="body-md"
                        sx={{ fontWeight: "bold", color: "white" }}
                      >
                        Hello, {user.username}
                      </Typography>
                      <Button
                        color="primary"
                        variant="plain"
                        onClick={logoutUser}
                      >
                        Logout
                      </Button>
                      {location.pathname !== "/dashboard" && (
                        <Button
                          color="primary"
                          variant="solid"
                          onClick={() => navigate("/dashboard")}
                        >
                          Dashboard
                        </Button>
                      )}
                    </>
                  )}
                </Box>
                <Box sx={{ display: { xs: "flex", md: "none" } }}>
                  <DropdownMenu />
                </Box>
              </>
            )}
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
};

export default NavigationBar;
